import React, { Component } from "react";
import { Endpoints } from "npmlinks-constants";

class GetRating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      starsBoolArray: [false, false, false, false, false],
      total: 0
    };
  }

  handleRatingOnclick = index => {
    const { total, starsBoolArray } = this.state;
    switch (index) {
      case 1:
        !starsBoolArray[index - 1]
          ? this.addTotal(index)
          : total === 1
          ? this.addTotal(index - 1)
          : ""
          ? total > 0 
          : this.addTotal(index);
        break;
      case 2:
        !starsBoolArray[index - 1]
          ? this.addTotal(index)
          : total === 2
          ? this.addTotal(index - 1)
          : ""
          ? total > 0 
          : this.addTotal(index);
        break;
      case 3:
        !starsBoolArray[index - 1]
          ? this.addTotal(index)
          : total === 3
          ? this.addTotal(index - 1)
          : ""
          ? total > 0 
          : this.addTotal(index);
        break;
      case 4:
        !starsBoolArray[index - 1]
          ? this.addTotal(index)
          : total === 4
          ? this.addTotal(index - 1)
          : ""
          ? total > 0 
          : this.addTotal(index);
        break;
      default:
        !starsBoolArray[index - 1]
          ? this.addTotal(index)
          : total === 5
          ? this.addTotal(index - 1)
          : ""
          ? total > 0 
          : this.addTotal(index);
    }
  };

  addTotal = total => {
    let starsBoolArray = this.state.starsBoolArray;
    for (let i = 0; i < 5; i++) starsBoolArray[i] = i < total ? true : false;
    this.setState({ starsBoolArray, total }, () => {
      this.updateTotalAndCallApi();
    });
  };

  updateTotalAndCallApi = () => {
    const { total } = this.state;
    const { updateTotal, isSubmit } = this.props;
    if (isSubmit) {
      console.log("\n\n\n part call api");
      updateTotal(total);
    } else {
      updateTotal(total);
    }
  };

  getStarIcon = (bool, index) => {
    const { isShowRating, rating } = this.props;
    const numberValue =
      rating && !Number.isInteger(rating) && parseInt(rating, 10);
    if (isShowRating && rating) {
      return index <= rating || index - numberValue === 1
        ? numberValue && index - numberValue === 1
          ? Endpoints.ratings.halfFilledStar
          : Endpoints.ratings.filled_star
        : Endpoints.ratings.empty_star;
    }
    return bool
      ? Endpoints.ratings.filled_star
      : Endpoints.ratings.empty_starv2;
  };

  componentDidMount() {
    const { isSubmit, currentRating } = this.props;
    if (isSubmit) {
      let starsBoolArray = this.state.starsBoolArray;
      for (let i = 0; i < currentRating; i++) {
        starsBoolArray[i] = true;
      }
      this.setState({ starsBoolArray, total: currentRating });
    }
  }

  render() {
    const { starSize,isShowRating} = this.props;
    const { starsBoolArray } = this.state;
    return (
      <div>
        {starsBoolArray.map((item, index) => (
          <img
            onClick={() => !isShowRating ? this.handleRatingOnclick(index + 1) : false}
            src={this.getStarIcon(item, index + 1)}
            alt="nnnow"
            height={starSize}
            className = "nw-rating-display-stars"
          />
        ))}
      </div>
    );
  }
}

GetRating.defaultProps = {
  isSubmit: false,
  starSize: "32px",
  isShowRating: false,
  rating: 0,
  currentRating: 0
};

const ProjectComponent = {
  component: GetRating
};

export default ProjectComponent;
