/* eslint-disable react/no-array-index-key */
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import { Button, Img, Loader } from "arv-reactcomponents";
import { PDP_SIZE_CHART_LABEL, PDP_SIZE_CHART_NOTE } from "npmlinks-constants";

@inject("ProductStore")
@observer
class PdpFitGuide extends Component {
  constructor() {
    super();
    this.state = {
      response: null,
      fitGuidaData: null,
      image: null,
      activeBlock: "inch",
    };
    this.showCmBlock = this.showCmBlock.bind(this);
    this.showInchBlock = this.showInchBlock.bind(this);
  }

  componentDidMount() {
    const { sizeChartId } = this.props;
    const { sizeChartData } = this.props.ProductStore;
    if (!sizeChartData) {
      this.props.ProductStore.getFitGuideDtails(sizeChartId).then(res =>
        this.setFitGuidaData(res),
      );
    } else {
      this.setFitGuidaData(sizeChartData.data);
    }
  }

  get inchBlockActive() {
    return (
      (this.state.activeBlock === "inch" && "nw-pdpfitguide-buttonactive") || ""
    );
  }

  get cmBlockActive() {
    return (
      (this.state.activeBlock === "cm" && "nw-pdpfitguide-buttonactive") || ""
    );
  }

  setFitGuidaData(res) {
    this.setState({
      response: res,
      fitGuidaData: res.data.measurements.inch,
      image: res.data.primaryImage,
    });
  }

  showCmBlock() {
    this.setState({
      fitGuidaData: this.state.response.data.measurements.cm,
      activeBlock: "cm",
    });
  }

  showInchBlock() {
    this.setState({
      fitGuidaData: this.state.response.data.measurements.inch,
      activeBlock: "inch",
    });
  }

  render() {
    const { fitGuidaData, image } = this.state;
    const { sizeRecommendation } = this.props;
    if (!fitGuidaData) {
      return (
        <div className="nw-pdpfitguide-loader">
          <Loader />
        </div>
      );
    }
    return (
      <div className="nw-pdpfitguide-wrapper">
        <div className="nw-pdpfitguide-innerwrap">
          <h3 className="nw-pdpfitguide-title">{PDP_SIZE_CHART_LABEL}</h3>
          { 
            image ? 
            <div className="nw-pdpfitguide-imagewrap">
              <Img className="nw-pdpfitguide-image" src={image} />
            </div>
          : null
          }
          <div className="nw-pdpfitguide-sizewrap nwc-custom-scrollbar">
            <div className="nw-pdpfitguide-buttons">
              <Button
                onClick={this.showInchBlock}
                className={`nw-pdpfitguide-button ${this.inchBlockActive}`}
              >
                Inches
              </Button>
              <Button
                onClick={this.showCmBlock}
                className={`nw-pdpfitguide-button ${this.cmBlockActive}`}
              >
                Centimeters
              </Button>
            </div>
            <table className="nw-pdpfitguide-table">
              <thead className="nw-pdpfitguide-thead">
                <tr className="nw-pdpfitguide-row">
                  {fitGuidaData[0].map((tableHead, index) => (
                    <th className="nw-pdpfitguide-th" key={index}>
                      {tableHead.attribute}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {fitGuidaData.map((tableBody, index) => (
                  <tr key={index}>
                    {tableBody.map(eachTd => (
                      <td
                        className="nw-pdpfitguide-td"
                        key={tableBody.attribute}
                      >
                        {eachTd.value}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="nw-pdpfitguide-note">
          {sizeRecommendation ? sizeRecommendation : PDP_SIZE_CHART_NOTE}
        </div>
      </div>
    );
  }
}

PdpFitGuide.wrappedComponent.defaultProps = {
  sizeRecommendation: "",
};

PdpFitGuide.wrappedComponent.propTypes = {
  ProductStore: PropTypes.shape({
    getFitGuideDtails: PropTypes.func.isRequired,
  }).isRequired,
  sizeChartId: PropTypes.string.isRequired,
  sizeRecommendation: PropTypes.string,
};

const getFinalComponent = component =>
  inject("ProductStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: PdpFitGuide.wrappedComponent,
  component: PdpFitGuide,
};

export default ProjectComponent;
