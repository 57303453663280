import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import PriceBlock from "../PriceBlock";
import ColorsBlock from "../ColorsBlock";
import SizeBlock from "../SizeBlock";
import SizeBlockV2 from "../SizeBlockV2";
import PromotionBlock from "../PromotionBlock";
import ProductTags from "../../ProductTags";
import { Button, Ripple, Utils } from "arv-reactcomponents";
import { PDP_PRODUCTVIEWBUTTON_TEXTS, PDP_CONFIG } from "npmlinks-constants";
import { sanitiseUrl } from "../../../utils";
import PincodeBlockV2 from "../../PdpComponents/PincodeBlockV2";
import PromotionBlockV2 from "../PromotionBlockV2";
import ExpressDelivery from "../ExpressDelivery";
import QuantityBlock from "../QuantityBlock";
import ColorsBlockV2 from "../ColorsBlockV2";
import GetRating from "../../../component/PdpComponents/RatingAndReview/GetRating";
import SnapmintText  from "../../PdpComponents/SnapmintText";

@inject("CommonStore", "ProductStore")
@observer
class ProductRightPanel extends Component {
  static target(isDesktop) {
    return isDesktop ? "_blank" : "";
  }
  get isNewClassName() {
    const { isNew } = this.props.pdpDetails;
    return isNew ? "" : "nwc-hide";
  }
  get isInStock() {
    const { inStock } = this.props.pdpDetails;
    return inStock ? "" : "nwc-hide";
  }

  get isOutOfStock() {
    const {
      ProductLoader,
      pdpDetails: { inStock }
    } = this.props;
    if (ProductLoader) {
      return <p className="nw-product-stock nwc-contentloader" />;
    }
    return inStock ? (
      ""
    ) : (
      <span className="nw-product-stock">OUT OF STOCK</span>
    );
  }

  get brandClickableUrl() {
    return this.props.pdpDetails.brandClickableUrl
      ? sanitiseUrl(this.props.pdpDetails.brandClickableUrl)
      : "";
  }

  get returnProductTag() {
    const { productTags } = this.props.pdpDetails;
    if (productTags && productTags.length) {
      return (
        <ProductTags.component
          tagData={productTags}
          className="nw-product-producttags"
        />
      );
    }
    return (
      <div
        className={`
          nw-product-isnewtxt
          ${this.isNewClassName}
        `}
      >
        {PDP_PRODUCTVIEWBUTTON_TEXTS.newText}
      </div>
    );
  }

  render() {
    const {
      className,
      buttonText,
      ProductLoader,
      selectedSku,
      skuId,
      showPriceInfoBlock,
      selectedQty,
      setSelectedQty,
      pdpDetails,
      mainStyle
    } = this.props;
    const {averageRating} = mainStyle || "";
    const {totalReviews} = mainStyle || "";
    const {reviewConfig} = mainStyle || {};
    const {ratingsCount} = mainStyle || {};
    const {
      skus,
      colors,
      discountRange,
      mrpRange,
      sellingPriceRange,
      promotions,
      inStock,
      styleId,
      brandName,
      name,
      sizeChartId,
      sizeRecommendation,
      tenantSizeLabel,
      sizeMappingEnabled,
      returnable
    } = this.props.pdpDetails;
    const sellingPrice = sellingPriceRange && sellingPriceRange.min || "";
    const { isDesktop } = this.props.CommonStore;
    const snapmintEnabled = process.env.REACT_APP_SNAPMINT_ENABLED;
    
    return (
      <div
        className={`nw-pdp-right-panel ${className}`}
        itemScope
        itemType="http://schema.org/Product"
      >
        <div className="nw-product-name">
          {this.returnProductTag}
          <h1 className="nw-product-brandtitle">
            <a
              className="nw-product-brandtxt-anchor"
              href={this.brandClickableUrl}
              target={ProductRightPanel.target(isDesktop)}
            >
              <span className="nw-product-brandtxt" itemProp="brand">
                {brandName}
              </span>
            </a>
            <span
              className="nw-product-title nw-product-titletxt"
              itemProp="name"
            >
              {""} {name}
            </span>
          </h1>
          {this.isOutOfStock}
        </div>
        {reviewConfig && reviewConfig.isEnabled && (reviewConfig.publishedReviewsDisplayThreshold <= ratingsCount) && averageRating &&
        <div className="nw-product-ratingcontainer-rightpanel">
          <GetRating.component
            starSize= "12px"
            isShowRating={true}
            rating={averageRating}
            />
            <div className="nw-rating-text">({ratingsCount} ratings)</div>
        </div>
        }
        <PriceBlock.component
          discountRange={discountRange}
          mrpRange={mrpRange}
          sellingPriceRange={sellingPriceRange}
          selectedSku={selectedSku}
          ProductLoader={ProductLoader}
          showTC
          promotions={promotions}
          className={`nw-product-price ${this.isInStock}`}
          showPriceInfoBlock={showPriceInfoBlock}
          styleId={styleId}
        />

        {snapmintEnabled == "true" && sellingPrice && <SnapmintText.component mrpRange={mrpRange} sellingPriceRange={sellingPriceRange} selectedSku={selectedSku}/>}
        <PromotionBlock.component
          promotions={promotions}
          ProductLoader={ProductLoader}
        />

        
        {isDesktop ? (
          <SizeBlock.component
            selectedSkuId={skuId}
            selectSku={this.props.selectSku}
            skus={skus}
            ProductLoader={ProductLoader}
            fitGuideModal={this.props.fitGuideModal}
            sizeChartId={sizeChartId}
            sizeRecommendation={sizeRecommendation}
            tenantSizeLabel={tenantSizeLabel}
            sizeMappingEnabled={sizeMappingEnabled}
          />
        ) : (
          <SizeBlockV2.component
            selectedSkuId={skuId}
            selectSku={this.props.selectSku}
            skus={skus}
            ProductLoader={ProductLoader}
            fitGuideModal={this.props.fitGuideModal}
            sizeChartId={sizeChartId}
            sizeRecommendation={sizeRecommendation}
            tenantSizeLabel={tenantSizeLabel}
            sizeMappingEnabled={sizeMappingEnabled}
          />
        )}
        <div className="nw-pdp-colorblockdesktoponly">
          <ColorsBlock.component
            styleId={styleId}
            setStyle={this.props.setStyle}
            setMainImage={this.props.setMainImage}
            ProductLoader={ProductLoader}
            colors={colors}
          />
        </div>
        <div className="nw-pdp-colorblockmobileonly">
          <ColorsBlockV2.component
            styleId={styleId}
            setStyle={this.props.setStyle}
            setMainImage={this.props.setMainImage}
            ProductLoader={ProductLoader}
            colors={colors}
          />
        </div>

        <PromotionBlockV2.component
          promotions={promotions}
          ProductLoader={ProductLoader}
        />
        
        {!isDesktop && (
          <PincodeBlockV2.component
            className={`nw-pdp-pincodeblock nw-pdp-pincodeblockv2`}
            selectedSkuId={skuId}
            getShippingDetails={this.props.ProductStore.getShippingDetails}
            styleId={styleId}
            inStock={inStock}
          />
        )}
        {!isDesktop && <ExpressDelivery.component returnable={returnable} />}
        {process.env.REACT_APP_SHOW_QUANTITY === "true" &&
          <QuantityBlock.component
            selectedSku={selectedSku}
            selectedQty={selectedQty}
            pdpDetails={pdpDetails}
            ProductLoader={ProductLoader}
            setSelectedQty={setSelectedQty}
          />
        }
        <div className="nw-pdp-button">
          <Ripple className="nwc-ripple-light">
            <Button
              className="nwc-btn-primary nw-pdp-addtobag"
              disabled={!inStock}
              onClick={this.props.addToBag}
            >
              {buttonText}
            </Button>
          </Ripple>
        </div>
      </div>
    );
  }
}
ProductRightPanel.wrappedComponent.defaultProps = {
  setStyle: Utils.noop,
  buttonText: PDP_CONFIG.PRODUCT_RIGHT_PANEL.ADD_TO_BAG_BUTTON,
  selectedSku: {},
  ProductLoader: false,
  skuId: "",
  pdpDetails: {
    brandClickableUrl: "",
    skus: [],
    colors: [],
    inStock: false,
    discountRange: {
      min: 0,
      max: 0
    },
    mrpRange: {
      min: 0,
      max: 0
    },
    sellingPriceRange: {
      min: 0,
      max: 0
    },
    isNew: false,
    promotions: [],
    brandName: "",
    name: "",
    styleId: "",
    sizeRecommendation: "",
    productTags: [],
    sizeMappingEnabled: false
  },
  showPriceInfoBlock: false
};

ProductRightPanel.wrappedComponent.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string.isRequired,
  setMainImage: PropTypes.func.isRequired,
  selectSku: PropTypes.func.isRequired,
  fitGuideModal: PropTypes.func.isRequired,
  setStyle: PropTypes.func,
  addToBag: PropTypes.func.isRequired,
  selectedSku: PropTypes.shape({}),
  ProductLoader: PropTypes.bool,
  skuId: PropTypes.string,
  pdpDetails: PropTypes.shape({
    brandClickableUrl: PropTypes.string,
    skus: PropTypes.arrayOf(PropTypes.shape({})),
    colors: PropTypes.shape([]),
    inStock: PropTypes.bool,
    sizeChartId: PropTypes.string,
    sizeRecommendation: PropTypes.string,
    discountRange: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number
    }),
    mrpRange: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number
    }),
    sellingPriceRange: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number
    }),
    isNew: PropTypes.bool,
    promotions: PropTypes.arrayOf(PropTypes.object),
    brandName: PropTypes.string,
    name: PropTypes.string,
    styleId: PropTypes.string,
    productTags: PropTypes.arrayOf(
      PropTypes.shape({
        tagUrl: PropTypes.string,
        tagText: PropTypes.string,
        tagTextColor: PropTypes.string
      })
    ),
    tenantSizeLabel: PropTypes.string,
    sizeMappingEnabled: PropTypes.string
  }),
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired
  }).isRequired,
  showPriceInfoBlock: PropTypes.bool
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ProductRightPanel.wrappedComponent,
  component: ProductRightPanel
};

export default ProjectComponent;
