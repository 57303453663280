/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Utils } from "arv-reactcomponents";
import { pageType } from "npmlinks-constants";

import Anchor from "../../Anchor";

@inject("CommonStore")
@observer
class ColorChip extends Component {
  static historyState(link) {
    return {
      pathname: link,
      state: {
        currentPage: pageType.pdp,
      },
    };
  }

  get style() {
    const { color } = this.props;
    const { swatchImage, hexCode } = color;

    let style = {
      backgroundColor: hexCode,
      backgroundImage: `url( ${swatchImage} )`,
      cursor: "pointer",
    };

    return style;
  }

  render() {
    const { color, onHover, setStyle, isSelected, className } = this.props;
    const { isDesktop } = this.props.CommonStore;

    if (isSelected) {
      const style = { cursor: "default", ...this.style};
      const selected = "selected";
      if (isDesktop) {
        return (
          <Anchor.component
            role="button"
            className={`nw-color-item ${className} ${selected}`}
            style={style}
            key={color.styleId}
            href={color.url}
            to={color.url}
            onMouseOver={() => onHover(color)}
            onClick={e => e.preventDefault()}
          />
        );
      }
      return (
        <div
          role="button"
          className={`nw-color-item ${className} ${selected}`}
          style={this.style}
          key={color.styleId}
          href={color.url}
          to={ColorChip.historyState(color.url)}
        />
      );
    }
    if (isDesktop) {
      return (
        <Anchor.component
          role="button"
          className={`nw-color-item ${className}`}
          style={this.style}
          key={color.styleId}
          href={color.url}
          to={color.url}
          onClick={e => setStyle(e, color.styleId, color.mainImage, color)}
          onKeyPress={e => setStyle(e, color.styleId, color.mainImage)}
          onMouseOver={() => onHover(color)}
          tabIndex="0"
        />
      );
    }
    return (
      <Anchor.component
        role="button"
        className={`nw-color-item ${className}`}
        style={this.style}
        key={color.styleId}
        href={color.url}
        to={ColorChip.historyState(color.url)}
        onClick={e => setStyle(e, color.styleId, color.mainImage, color)}
        onKeyPress={e => setStyle(e, color.styleId, color.mainImage)}
        tabIndex="0"
      />
    );
  }
}

ColorChip.wrappedComponent.defaultProps = {
  onHover: Utils.noop,
  setStyle: Utils.noop,
  isSelected: false,
  className: "",
};

ColorChip.wrappedComponent.propTypes = {
  className: PropTypes.string,
  color: PropTypes.shape({}).isRequired,
  onHover: PropTypes.func,
  setStyle: PropTypes.func,
  isSelected: PropTypes.bool,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ColorChip.wrappedComponent,
  component: ColorChip,
};

export default ProjectComponent;
