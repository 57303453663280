/* eslint-disable no-restricted-syntax */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";

import SizeRecommendationBlock from "../SizeRecommendationBlock";
import { Button } from "arv-reactcomponents";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import {
  PDP_SIZE_CHART_LABEL,
  pageType,
  PDP_SIZE_CHART_LABELV2,
  PDP_SIZE_MODAL_TITLE2,
  PAYMENTPAGE
} from "npmlinks-constants";


@inject("ProductStore", "CommonStore")
@observer
class SizeBlockV2 extends Component {
  static activeButton(isOpen) {
    return isOpen ? "is-active" : "is-inactive";
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedMeasurement: null,
      sellableQuantityText: "",
      isOpen: true
    };

    this.selectSku = this.selectSku.bind(this);
    this.onClickfitGuideModal = this.onClickfitGuideModal.bind(this);
    this.setActive = this.setActive.bind(this);
    this.getSizeChipArray = this.getSizeChipArray.bind(this);
    this.getSizeBlockLabelSection = this.getSizeBlockLabelSection.bind(this);
    this.getSizeBlockListSection = this.getSizeBlockListSection.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sizeChartId !== this.props.sizeChartId) {
      this.setState({ selectedMeasurement: null });
    }
  }

  get properMeasurementsObject() {
    const { ProductStore } = this.props;
    const { sizeChartData } = ProductStore;
    const measurements =
      sizeChartData &&
      sizeChartData.data &&
      sizeChartData.data.data &&
      toJS(sizeChartData.data.data.measurements);
    const obj = {};

    for (const unit in measurements) {
      if (unit && measurements[unit]) {
        measurements[unit].forEach(value => {
          if (unit !== "inch") {
            return;
          }

          let measurementsObj = [];
          const unitData = value.filter(item => item.value !== "");
          if (unitData) {
            unitData.forEach((val, key) => {
              if (key === 0) {
                measurementsObj = obj[val.value] || measurementsObj;
                const valInLowerCase = (val.value || "")
                  .replace(/\s/g, "")
                  .toLowerCase();
                obj[valInLowerCase] = measurementsObj;
              } else {
                measurementsObj.push({
                  attribute: val.attribute,
                  value: val.value,
                  unit
                });
              }
            });
          }
        });
      }
    }

    return obj;
  }

  onClickfitGuideModal() {
    this.props.fitGuideModal();
    const category =
      window.pageType === pageType.pdp
        ? Event.category.PRODUCT_DESCRIPTION_PAGE
        : Event.category.FAVOURITES_PAGE;
    Analytics.trackEvent({
      action: Event.action.FIT_GUIDE_CLICK,
      category
    });
  }

  selectSku(sku, sellableQuantity) {
    const { selectSku } = this.props;
    const skuSizeInLowerCase = (sku.tenantSize || sku.size || "")
      .replace(/\s/g, "")
      .toLowerCase();

    this.setState({
      selectedMeasurement: this.properMeasurementsObject[skuSizeInLowerCase],
      sellableQuantityText: sellableQuantity
    });

    selectSku(sku, sellableQuantity);
  }

  addUnitIfNone(value, unit) {
    if (!/[a-zA-Z]/g.test(value)) {
      return `${value} ${unit}`;
    }

    return value;
  }

  setActive() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  getSizeChipArray(sizeArray) {
    const { selectedSkuId } = this.props;
    return sizeArray.map(sku => {
      const disabled = !sku.inStock || !sku.sellableQuantity > 0;
      const sellableQuantity =
        sku.sellableQuantity <= 3
          ? `Hurry only ${sku.sellableQuantity} items left`
          : "";
      const sizeAttr = {
        disabled
      };
      const selected = selectedSkuId === sku.skuId ? "selected" : "";
      const skuFinalSize = sku.tenantSize || sku.size || "";
      const skuSizeInLowerCase = skuFinalSize.replace(/\s/g, "").toLowerCase();
      const eachMeasurementObj = this.properMeasurementsObject[
        skuSizeInLowerCase
      ];

      return (
        <Button
          itemProp="offers"
          itemScope
          itemType="http://schema.org/Offer"
          className={`nw-size-chip  ${selected}`}
          key={sku.skuId}
          onClick={() => this.selectSku(sku, sellableQuantity)}
          {...sizeAttr}
        >
          <span itemProp="sku" className="nwc-hide">
            {sku.skuId}
          </span>
          <span itemProp="priceCurrency" content="INR" className="nwc-hide">
          {`${PAYMENTPAGE.NNNOW_CURRENCY}`}
          </span>
          <span itemProp="price" className="nwc-hide">
            {sku.price}
          </span>
          <span
            itemProp="availability"
            href="http://schema.org/InStock"
            className="nwc-hide"
          >
            {sku.inStock ? "InStock" : "OutOfStock"}
          </span>
          {sku.tenantSize || sku.size}
          {(eachMeasurementObj && (
            <div className="nw-sizeblock-buttonhover nw-desktop-only">
              <div className="nw-sizeblock-buttonhoverwrapper">
                {eachMeasurementObj.map(obj => (
                  <div key={obj.attribute}>
                    <span className="nw-sizeblock-hoverlabel">{`${obj.attribute}:`}</span>
                    {` ${obj.value}`}
                  </div>
                )) || null}
                <div className="nw-sizeblock-unitmsg">*Units in inches</div>
              </div>
            </div>
          )) ||
            null}
        </Button>
      );
    });
  }

  getSizeBlockLabelSection() {
    const { sizeChartId } = this.props;
    const { isDesktop } = this.props.CommonStore;
    const { isOpen } = this.state;
    return (
      <div className="nw-sizeblock-label">
        <div className="nw-sizeblock-title">
          {this.sizeBlockTitle}
          {isDesktop ? ":" : ""}
        </div>
        {isDesktop ? (
          sizeChartId && (
            <Button
              className="nw-sizechart-button"
              onClick={this.onClickfitGuideModal}
            >
              {PDP_SIZE_CHART_LABEL}
            </Button>
          )
        ) : (
          <div className="nw-pdp-sizeopendropdowncontainer">
            <i
              className={`icomoon-arrow_right nw-pdp-sizeblockrightarrow ${SizeBlockV2.activeButton(
                isOpen
              )}`}
              onClick={this.setActive}
            />
          </div>
        )}
      </div>
    );
  }

  getSizeBlockListSection(skus) {
    const sizeArray = this.getSizeChipArray(skus);
    const { isDesktop } = this.props.CommonStore;
    const { sizeChartId, sizeRecommendation } = this.props;
    const { isOpen, selectedMeasurement, sellableQuantityText } = this.state;
    const selectedMeasurementLength =
      selectedMeasurement && selectedMeasurement.length - 1;

    return (
      <div
        className={` nwc-custom-scrollbar nw-sizeblock-list ${SizeBlockV2.activeButton(
          isOpen
        )}`}
      >
        {isDesktop ? sizeArray : ""}
        {(selectedMeasurement && (
          <div className="nw-sizeblock-mbuttonhover nw-mobile-only">
            <div className="nw-sizeblock-mbuttonhoverwrapper">
              {selectedMeasurement.map((obj, index) => (
                <div
                  className="nw-sizeblock-meachmeasurement"
                  key={obj.attribute}
                >
                  <span className="nw-sizeblock-mhoverlabel">{`${obj.attribute} `}</span>
                  {` ${obj.value} inch`}
                  {index !== selectedMeasurementLength ? ", " : ""}
                </div>
              )) || null}
            </div>
          </div>
        )) ||
          null}
        {!isDesktop ? (
          <div className="nw-sizearray-block">{sizeArray}</div>
        ) : (
          ""
        )}

        {!isDesktop
          ? sizeChartId && (
              <Button
                className="nw-sizechart-button"
                onClick={this.onClickfitGuideModal}
              >
                {PDP_SIZE_CHART_LABELV2}
              </Button>
            )
          : ""}
        {!isDesktop ? (
          <div className="nw-sellablequantitytext-block">
            {sellableQuantityText}
          </div>
        ) : (
          ""
        )}

        <SizeRecommendationBlock.component
          sizeRecommendation={sizeRecommendation}
        />
      </div>
    );
  }

  get sizeBlockTitle() {
    const { tenantSizeLabel } = this.props;
    return tenantSizeLabel || PDP_SIZE_MODAL_TITLE2;
  }

  render() {
    const { className, skus, ProductLoader, sizeRecommendation } = this.props;
    let sizeBlock = null;

    if (ProductLoader) {
      sizeBlock = (
        <div className="nw-sizeblock">
          <div className="nw-sizeblock-title nwc-contentloader" />
          <div className="nw-sizeblock-list nwc-contentloader" />
        </div>
      );
    } else if (skus && skus.length > 0) {
      sizeBlock = (
        <div className={`nw-sizeblock-container ${className} `}>
          {this.getSizeBlockLabelSection()}
          {this.getSizeBlockListSection(skus)}
        </div>
      );
    }
    return sizeBlock;
  }
}

SizeBlockV2.defaultProps = {
  className: "",
  selectedSkuId: "",
  skus: [],
  sizeChartId: "",
  fitGuideModal: "",
  sizeRecommendation: "",
  sizeMappingEnabled: false
};

SizeBlockV2.propTypes = {
  className: PropTypes.string,
  sizeRecommendation: PropTypes.string,
  selectSku: PropTypes.func.isRequired,
  skus: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSkuId: PropTypes.string,
  sizeChartId: PropTypes.string,
  ProductLoader: PropTypes.bool.isRequired,
  fitGuideModal: PropTypes.func,
  ProductStore: PropTypes.shape({
    sizeChartData: PropTypes.shape({})
  }).isRequired,
  tenantSizeLabel: PropTypes.string,
  sizeMappingEnabled: PropTypes.string
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: SizeBlockV2,
  component: SizeBlockV2
};

export default ProjectComponent;
