import React from 'react';
import { Select, Toastr } from "arv-reactcomponents";
import { PDP_QUANTITY_BLOCK_TITLE, PDP_QUANTITY_WARNING_TEXT } from "npmlinks-constants";

const getList = item =>{
	return (
		<li className="nw-common-selectlist" key={item}>
			{item}
		</li>
	);
}

const getMinimunQty =(skuQuantity, tenantConfigQty) => {
	return skuQuantity >= tenantConfigQty ? tenantConfigQty : skuQuantity;
}

function QuantityBlock(props) {

	const updateBag = newQty => {
		const { setSelectedQty, selectedSku } = props;
		const isSizeSelected = selectedSku && selectedSku.size || null ;
		if (!isSizeSelected) {
			Toastr.showToastr({
				className: "nwc-toastr-list-notification nw-toastr",
				message: PDP_QUANTITY_WARNING_TEXT,
				timeout: 3000,
			});
		}
		else {
			setSelectedQty(newQty);
		}
	}

	const qtyRange = () => {
		const {selectedSku , pdpDetails} = props;
		const { tenantsellableQty } = pdpDetails;
		let maxSelectableQty = tenantsellableQty;
		if(Object.keys(selectedSku).length){
			maxSelectableQty = getMinimunQty(selectedSku.sellableQuantity,tenantsellableQty);
		}
		return  Array.from({ length: maxSelectableQty }, (v, k) => k + 1);
	}

	const { selectedQty, ProductLoader } = props;
	if (ProductLoader) {
		return null;
	}
	return (
		<div className=" nw-productrightpanel-quantity">
			<div className="nw-productrightpanel-qtytext">{PDP_QUANTITY_BLOCK_TITLE}:</div>
			<Select
				className="nwc-select-secondary nwc-select nw-common-select nw-productrightpanel-dropdown"
				selectedValue={selectedQty}
				inpList={qtyRange()}
				getSelection={updateBag}
				renderList={getList}
				compareProp="displayName"
			/>
		</div>
	);
}

const getFinalComponent = component => component;

const ProjectComponent = {
	getFinalComponent,
	wrapper: QuantityBlock,
	component: QuantityBlock,
};

export default ProjectComponent;