/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from "react";
import { Button, Toastr, Utils } from "arv-reactcomponents";
import { observer, inject } from "mobx-react";
import PropTypes from "prop-types";
import GTM from "../../analytics/GTM";
import { reaction } from "mobx";
import Analytics from "../../analytics";
import Event from "../../analytics/eventFactory";
import { FAVNSHARE } from "npmlinks-constants";

@inject("BrandStore", "LoginStore")
@observer
class PdpFavoriteComponentV2 extends Component {
    static toastrMessages(message, className, time) {
        Toastr.showToastr({
      className: `nwc-toastr-list-${className || "notification"} nw-toastr`,
            message,
      timeout: time || 3000,
        });
    }

    static triggerGTM(pdpDetails) {
    GTM.pushWithEvent(pdpDetails, Event.GTMEvent.PRODUCT_WISHLIST);
    }

    constructor() {
        super();
        this.state = {
            faved: null,
            loading: false,
            addingPending: false,
        };
        this.init = this.init.bind(this);
        this.reactToLogin = this.reactToLogin.bind(this);
        this.toggleFav = this.toggleFav.bind(this);
        this.addToFav = this.addToFav.bind(this);
        this.checkIfBlogAdded = this.checkIfBlogAdded.bind(this);
        this.removeFromFav = this.removeFromFav.bind(this);
        this.toggleLoading = this.toggleLoading.bind(this);
        this.reactionToBrandFav = this.reactionToBrandFav.bind(this);
    }
    componentDidMount() {
        this.init();
        this.reactToLogin();
        this.reactionToBrandFav();
    }
    get favorited() {
        const { isUserLoggedIn } = this.props.LoginStore;
        const { faved } = this.state;
        return isUserLoggedIn && faved ? "active" : "";
    }
    reactionToBrandFav() {
        reaction(
            () => this.props.BrandStore.favBlogs,
            favBlogs => {
                if (favBlogs && favBlogs.length) {
                    this.init();
                }
            },
        );
    }
    reactToLogin() {
        reaction(
            () => this.props.LoginStore.isUserLoggedIn,
            isUserLoggedIn => {
                if (isUserLoggedIn) {
                    this.init();
                }
            },
        );
    }
    checkIfBlogAdded(response) {
        const { id } = this.props;
        const faved =
            (response &&
                response.find(
                    item =>
                        item.brandId === id || item.blogId === id || item.styleId === id,
                )) ||
            null;
        this.setState({ faved });
    }
    init() {
        const { isUserLoggedIn } = this.props.LoginStore;
        const { favBlogs } = this.props.BrandStore;

        if (isUserLoggedIn && favBlogs && favBlogs.length) {
            this.toggleLoading();
            this.checkIfBlogAdded(favBlogs);
            if (this.state.addingPending) {
                this.setState({ addingPending: false });
                this.toggleFav(true);
              }
        }
    }
    toggleFav(postLogin, e) {
        if (e) {
            Utils.preventDefault(e);
            Utils.preventEventPropagation(e);
        }
        const { id, category } = this.props;
        const { isUserLoggedIn } = this.props.LoginStore;
        if (!isUserLoggedIn) {
            this.setState({ addingPending: true });
            this.props.LoginStore.setloginModal(true);
            Analytics.trackEvent({
                action: Event.action.SHORTLIST_PRE_LOGIN,
                label: id,
                category: category || window.pageType,
            });
            return null;
        }
        if (!this.favorited || postLogin) {
            this.addToFav();
        } else if (!postLogin) {
            this.removeFromFav();
        }
        return null;
    }
    addToFav() {
        const { id, type, pdpDetails, category } = this.props;
        let body;
        switch (type) {
            case "blogs":
                body = [{ blogId: id }];
                break;
            case "brands":
                body = [{ brandId: id }];
                break;
            default:
                body = [{ styleId: id }];
        }
        this.toggleLoading();
        this.props.BrandStore.addtoFav(JSON.stringify(body), type).then(
            response => {
                PdpFavoriteComponentV2.toastrMessages(FAVNSHARE.FAV.SUCCESSFULL_ADD, "success");
                this.toggleLoading();
                this.checkIfBlogAdded(response);
                Analytics.trackEvent({
                    action: Event.action.SHORLIST_SUCCESS,
                    label: id,
                    category: category || window.pageType,
                });
                PdpFavoriteComponentV2.triggerGTM(pdpDetails);
            },
            () => {
                PdpFavoriteComponentV2.toastrMessages(FAVNSHARE.FAV.ERROR, "success");
                this.toggleLoading();
            },
        );
    }
    removeFromFav() {
        this.toggleLoading();
        const body= {
            favouriteId: this.state.faved.id,
            favtype: this.props.type,
        };
       
        this.props.BrandStore.removeFav(JSON.stringify(body)).then(
            response => {
                PdpFavoriteComponentV2.toastrMessages(FAVNSHARE.FAV.SUCCESSFULL_REMOVED, "success");
                this.toggleLoading();
                this.checkIfBlogAdded(response);
                Analytics.trackEvent({
                    action: Event.action.SHORLIST_REMOVED,
                    label: this.props.id,
                    category: this.props.category || window.pageType,
                });
            },
            () => {
                PdpFavoriteComponentV2.toastrMessages(FAVNSHARE.FAV.ERROR, "success");
                this.toggleLoading();
            },
        );
    }
    toggleLoading() {
        this.setState({ loading: !this.state.loading });
    }

    get getFavoritedClass(){
        return this.favorited ? 'icomoon-heart' : "icomoon-heart-o"
    }
    
    render() {
        const { view, className } = this.props;
        return (
            <Fragment>
                <Button
                    className={`nw-favnshare-docfavbutton nw-favnshare-docfavbuttonv2 is-${view} ${className} ${
                        this.favorited
                        }`}
                    onClick={(e) => this.toggleFav(false, e)}
                >
                    <i
                        className={`nw-favnshare-docfavicon is-${view} ${this.getFavoritedClass}`}
                    />
                </Button>                
            </Fragment>
        );
    }
}

PdpFavoriteComponentV2.defaultProps = {
    view: "relative",
    id: "",
    className: "",
    type: "blogs",
    pdpDetails: [],
  };
  
  PdpFavoriteComponentV2.propTypes = {
    className: PropTypes.string,
    view: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    pdpDetails: PropTypes.shape({}),
    LoginStore: PropTypes.shape({
      isUserLoggedIn: PropTypes.bool,
      setloginModal: PropTypes.func.isRequired,
    }).isRequired,
    BrandStore: PropTypes.shape({
      getAllFav: PropTypes.func,
      addtoFav: PropTypes.func,
      removeFav: PropTypes.func,
    }).isRequired,
  };

  export default PdpFavoriteComponentV2;
