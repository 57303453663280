import React from "react";
import {
  Img,
} from "arv-reactcomponents";
import { PDP_NONRETURNBLOCK_TEXT1, PDP_NONRETURNBLOCK_TEXT2, Endpoints } from "npmlinks-constants";


const PdpNonReturnBlock = () => {
  return (
    <div className="nw-pdpnonreturnblock-container">
      {Endpoints.PDP_NON_RETURNABLE_LOGO ? (
        <Img className="nw-pdpnonreturnblock-image" src={Endpoints.PDP_NON_RETURNABLE_LOGO} alt="returnpolicy" />
      ) : null}
      <div className="nw-pdpnonreturnblock-textwrapper">
        <span className="nw-pdpnonreturnblock-text1">{PDP_NONRETURNBLOCK_TEXT1}</span>
        <span className="nw-pdpnonreturnblock-subtext2">{PDP_NONRETURNBLOCK_TEXT2}</span>
      </div>
    </div>
  );
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: PdpNonReturnBlock,
  component: PdpNonReturnBlock,
};

export default ProjectComponent;
