import React, { Component } from "react";
import PropTypes from "prop-types";

import { Img } from "arv-reactcomponents";
import { productTags } from "npmlinks-constants";

class ProductTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageErrorClass: "",
    };

    this.onImageError = this.onImageError.bind(this);
  }

  onImageError() {
    this.setState({
      imageErrorClass: "nwc-hide",
    });
  }

  render() {
    const { tagData, className } = this.props;
    const { imageErrorClass } = this.state;
    if (tagData && tagData.length) {
      const productTag = tagData[tagData.length - 1];
      const {
        tagUrl,
        tagText,
        tagTextColor = productTags.DEFAULT_PRODUCT_TAG_COLOR,
      } = productTag;
      return (
        <div className={`nw-producttags-container ${className}`}>
          <Img
            className={`nw-producttags-icon ${imageErrorClass}`}
            src={tagUrl}
            onError={() => this.onImageError()}
          />
          <span style={{ color: tagTextColor }} className="nw-producttags-text">
            {tagText}
          </span>
        </div>
      );
    }
    return null;
  }
}

ProductTags.defaultProps = {
  tagData: [],
  className: "",
};

ProductTags.propTypes = {
  tagData: PropTypes.arrayOf(
    PropTypes.shape({
      tagUrl: PropTypes.string,
      tagText: PropTypes.string,
      tagTextColor: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: ProductTags,
  component: ProductTags,
};

export default ProjectComponent;
