import React, { Component } from "react";
import { LOAD_SNAPMINT } from "../../../utils";

class SnapmintText extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (process.env.REACT_APP_SNAPMINT_ENABLED == "true") {
      LOAD_SNAPMINT();
    }
  }
  componentDidUpdate() {
    if (process.env.REACT_APP_SNAPMINT_ENABLED == "true") {
      LOAD_SNAPMINT();
    }
  }

  get sellingPriceText() {
    const { mrpRange, sellingPriceRange, selectedSku } = this.props;
    if (sellingPriceRange && mrpRange) {
      const { price, skuId } = selectedSku;
      if (skuId && price) {
        return price;
      } else {
        return sellingPriceRange && sellingPriceRange.min;
      }
    }
    return null;
  }

  render() {
    const { price } = this.props;
    return (
      <div className="nw-snapmint-text">
        <div className="snap_emi_txt"></div>
        <span
          className="snapmint_lowest_emi_value"
          style={{ display: "none" }}
          data-snapmint-price={this.sellingPriceText}
          data-snapmint-merchant_id="2435"
          data-snapmintpage="products_page"
        ></span>
      </div>
    );
  }
}

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: SnapmintText,
  component: SnapmintText
};

export default ProjectComponent;
