import React, { Component } from "react";
import PropTypes from "prop-types";
import { PDP_CONFIG, BRAND_NAME, Endpoints } from "npmlinks-constants";
import { Img, Button } from "arv-reactcomponents";
import { observer, inject } from "mobx-react";
import { toJS } from "mobx";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";

@inject("ProductStore")
@observer
class ExpressDelivery extends Component {
  constructor() {
    super();
    this.state = {
      showAll: false
    };
  }

  getProductsUrl = (store_id, pincode) => {
    return (
      window &&
      window.location &&
      window.location.origin &&
      `${window.location.origin}/products?f-ns=${store_id}&p-st=${pincode}&r=20`
    );
  };

  getDistace = item => {
    return item.distance ? `${item.distance.toFixed()} Kms Away` : "";
  };

  shopAStoreClick = () => {
    Analytics.trackEvent({
      action: Event.action.STORES_NEARBY_CLICK,
      category: Event.category.PRODUCT_DESCRIPTION_PAGE
    });
  };

  showStoreDetails = localStorePickData => {
    let { storePickData } = this.props.ProductStore;
    storePickData = toJS(storePickData);
    if (localStorePickData && localStorePickData.length) {
      return (
        <div>
          <div className="nw-pdp-store-infotitle">
            {PDP_CONFIG.EXPRESS_DELIVERY.nearby_store} ({storePickData && storePickData.length})
          </div>
          {localStorePickData &&
            localStorePickData.map(item => {
              return (
                <div className="nw-pdp-store-infocontainer">
                  <div className="nw-pdp-store-infoblock">
                    <div className="nw-pdp-store-name">{item.fcName}</div>
                    <div>
                      {item.town}, {item.state}, {item.streetNo},{" "}
                      {item.streetName}.{" "}
                      <span className="nw-pdp-store-distance">
                        {this.getDistace(item)}
                      </span>{" "}
                    </div>
                  </div>
                  <div>
                    <a href={this.getProductsUrl(item.fcId, item.postCode)}>
                      <Button
                        className="nw-shop-a-stores-btn"
                        onClick={this.shopAStoreClick}
                      >
                        {PDP_CONFIG.EXPRESS_DELIVERY.shop_a_store_btn}
                      </Button>
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      );
    }
    return <span />;
  };

  viewMoreClick = () => {
    this.setState({ showAll: true });
  };

  getStoreDetails = (storePickData, showAll) => {
    if (
      (storePickData && storePickData.length <= 4) ||
      (storePickData && storePickData.length && showAll)
    ) {
      return this.showStoreDetails(storePickData);
    } else {
      return (
        <div>
          {this.showStoreDetails(storePickData.slice(0, 4))}
          <div className="nw-pdp-store-viewall" onClick={this.viewMoreClick}>
            View More
          </div>
        </div>
      );
    }
  };

  render() {
    const { returnable } = this.props;
    let {
      sddDeliveryMode,
      nddDeliveryMode,
      standardDeliveryMode,
      storePickData
    } = this.props.ProductStore;
    sddDeliveryMode = toJS(sddDeliveryMode);
    nddDeliveryMode = toJS(nddDeliveryMode);
    standardDeliveryMode = toJS(standardDeliveryMode);
    storePickData = toJS(storePickData);
    const { showAll } = this.state;

    return (
      <div className="nw-pdp-deliverydetailsmaincontainer">
        {sddDeliveryMode || nddDeliveryMode || standardDeliveryMode ? (
          <div className="nw-pdp-promolabel">
            {PDP_CONFIG.EXPRESS_DELIVERY.title}
          </div>
        ) : (
          ""
        )}
        <div className="nw-pdp-deliverydetailscontainer">
          {sddDeliveryMode ? (
            <div className="nw-pdp-deliverydetailssubcontainer">
              <snap className="nw-pdp-deliverytext">
                {PDP_CONFIG.EXPRESS_DELIVERY.delivery_today.title}
              </snap>
            </div>
          ) : (
            ""
          )}
          {nddDeliveryMode ? (
            <div className="nw-pdp-deliverydetailssubcontainer">
              <snap className="nw-pdp-deliverytext">
                {PDP_CONFIG.EXPRESS_DELIVERY.delivery_tomorrow.title}
              </snap>
            </div>
          ) : (
            ""
          )}
          {standardDeliveryMode ? (
            <div className="nw-pdp-deliverydetailssubcontainer">
              <snap className="nw-pdp-deliverytext">
                {PDP_CONFIG.EXPRESS_DELIVERY.delivery_week.title}
              </snap>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="nw-pdp-offeringtext">
          <Img
            src={Endpoints.delivery_truck}
            alt={BRAND_NAME}
            className="nw-pdp-offeringimg"
          />
          <div>{PDP_CONFIG.EXPRESS_DELIVERY.free_std_delivery_text}</div>
        </div>
        {returnable ? (
          <div className="nw-pdp-offeringtext">
            <Img
              src={Endpoints.exchange}
              alt={BRAND_NAME}
              className="nw-pdp-offeringimg"
            />
            <div>{PDP_CONFIG.EXPRESS_DELIVERY.return_policy_text}</div>
          </div>
        ) : (
          ""
        )}
        <div className="nw-pdp-offeringtext">
          <Img
            src={Endpoints.rupee}
            alt={BRAND_NAME}
            className="nw-pdp-offeringimg"
          />
          <div>{PDP_CONFIG.EXPRESS_DELIVERY.cod_text}</div>
        </div>
        <div className="nw-pdp-offeringtext">
          <Img
            src={Endpoints.coin}
            alt={BRAND_NAME}
            className="nw-pdp-offeringimg"
          />
          <div>{PDP_CONFIG.EXPRESS_DELIVERY.loyalty_points}</div>
        </div>
        {this.getStoreDetails(storePickData, showAll)}
      </div>
    );
  }
}

ExpressDelivery.wrappedComponent.propTypes = {
  sddDeliveryMode: PropTypes.string,
  nddDeliveryMode: PropTypes.string,
  standardDeliveryMode: PropTypes.string
};

const getFinalComponent = component =>
  inject("ProductStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ExpressDelivery.wrappedComponent,
  component: ExpressDelivery
};

export default ProjectComponent;
