import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import ColorChip from "../ColorChip";

class ColorsBlockV2 extends Component {
  static activeButton(isOpen) {
    return isOpen ? "is-active" : "is-inactive";
  }

  constructor() {
    super();
    this.state = {
      colorName: "",
      isOpen: true
    };
    this.defaultColorName = "";
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.setStyle = this.setStyle.bind(this);
    this.createColorChip = this.createColorChip.bind(this);
    this.setActive = this.setActive.bind(this);
  }
  setStyle(e, styleId, mainImage, color) {
    const { primaryColor, secondaryColor } = color;
    const colorName = secondaryColor || primaryColor;
    if (this.state.colorName !== colorName) this.setState({ colorName });
    this.props.setStyle(e, styleId, mainImage);
  }
  createColorChip(color) {
    if (!color) {
      return null;
    }
    let selected = false;
    const { primaryColor, secondaryColor, styleId } = color;
    if (styleId === this.props.styleId) {
      const colorName = secondaryColor || primaryColor;
      this.defaultColorName = colorName;
      selected = true;
    }
    return (
      <ColorChip.component
        color={color}
        onHover={this.mouseEnter}
        setStyle={this.setStyle}
        isSelected={selected}
        key={styleId}
      />
    );
  }
  mouseEnter(color) {
    const { primaryColor, secondaryColor, mainImage } = color;
    const colorName = secondaryColor || primaryColor;
    this.props.setMainImage(mainImage);
    this.setState({ colorName });
  }
  mouseLeave() {
    const { defaultColorName: colorName } = this;
    this.props.setMainImage(null);
    this.setState({ colorName });
  }

  setActive() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { ProductLoader, colors: colorsObj, title, mwebTitle } = this.props;
    let colorsArray = [];
    let colors = null;
    const { isOpen } = this.state;
    const totalColor = Object.values(colorsObj).reduce(
      (acc, item) => acc + item.length,
      0
    );
    if (ProductLoader) {
      colors = (
        <Fragment>
          <div className="nw-color-title nwc-contentloader" />
          <div className="nw-color-block">
            <div className="nwc-contentloader" />
          </div>
        </Fragment>
      );
    } else if (colorsObj) {
      colorsArray = Object.entries(colorsObj).map(([finish, colorArray]) => (
        <div
          className="nw-color-chips"
          key={finish}
          onMouseOut={this.mouseLeave}
          onBlur={this.mouseLeave}
        >
          {finish ? <div className="nw-color-finish">{finish}</div> : ""}
          {colorArray.map(color => this.createColorChip(color))}
        </div>
      ));
      colors = (
        <div className="nw-color-blockcontainer">
          <div className="nw-color-title">
            <span className="nw-color-titledesktoponly">{title}</span>&nbsp;
            <span className="nw-color-titlemobileonly">
              {totalColor} {mwebTitle}
            </span>&nbsp;
            <span className={`nw-color-name ${ColorsBlockV2.activeButton(isOpen)}`}>
              : {this.state.colorName || this.defaultColorName}
            </span>
            <div className="nw-pdp-colorblockopendropdowncontainer">
              <i
                className={`icomoon-arrow_right nw-pdp-colorblockrightarrow ${ColorsBlockV2.activeButton(
                  isOpen
                )}`}
                onClick={this.setActive}
              />
            </div>
          </div>
          <div
            className={`nw-color-block ${ColorsBlockV2.activeButton(isOpen)}`}
          >
            {colorsArray}
          </div>
        </div>
      );
    }
    return colors;
  }
}
ColorsBlockV2.defaultProps = {
  styleId: "",
  title: "COLOR:",
  mwebTitle: "Color Options"
};

ColorsBlockV2.propTypes = {
  setMainImage: PropTypes.func.isRequired,
  styleId: PropTypes.string,
  setStyle: PropTypes.func.isRequired,
  ProductLoader: PropTypes.bool.isRequired,
  colors: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  mwebTitle: PropTypes.string
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: ColorsBlockV2,
  component: ColorsBlockV2
};

export default ProjectComponent;
