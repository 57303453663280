import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Label, Button } from "arv-reactcomponents";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import { Toastr } from "arv-reactcomponents";
import { subPromoSeparator } from "npmlinks-constants";

@inject("ProductStore", "CommonStore")
@observer
class PromotionBlock extends Component {
  static redirectToListing(promo) {
    const brand = promo.brands.length && promo.brands[0] ? `${subPromoSeparator}${promo.brands[0]}`: "";
    return `/products?f-pro=${encodeURI(
      promo.displayName
    )}${brand}`;
  }
  static getTarget(isDesktop) {
    return isDesktop ? "_blank" : "";
  }
  static promoClick(promo) {
    try {
      const el = document.createElement('textarea');
      el.value = promo;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy',false);
      document.body.removeChild(el);
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: "Text Copied",
        timeout: 3000,
      });
      Analytics.trackEvent({
        action: Event.action.OFFER_CODE_CLICK,
        label: promo,
        category: Event.category.PRODUCT_DESCRIPTION_PAGE,
      });
    } catch (err) {
      console.log("Oops, unable to copy");
    }
  }
  constructor() {
    super();
    this.state = {
      isPromotions: false,
    };
    this.displayPromoBlock = false;
    this.togglePromotions = this.togglePromotions.bind(this);
    this.clearProductData = this.clearProductData.bind(this);
    this.handleShowEligibleProducts = this.handleShowEligibleProducts.bind(
      this,
    );
  }

  
  get isPromoHidden() {
    const { isPromotions } = this.state;
    return !isPromotions ? "" : "nwc-hide";
  }
  get shouldPromoBlockDisplayed() {
    return this.displayPromoBlock ? "" : "nwc-hide";
  }

  handleShowEligibleProducts(promo) {
    const { isDesktop } = this.props.CommonStore;
    if (
      promo &&
      (Object.keys(promo).indexOf("showEligibleProducts") === -1 ||
        promo.showEligibleProducts)
    ) {
      return (
        <div className="nw-promo-othereligible-cta">
          <Link
            className="nwc-btn nwc-btn-secondary nw-other-eligible-cta"
            href={PromotionBlock.redirectToListing(promo)}
            to={PromotionBlock.redirectToListing(promo)}
            target={PromotionBlock.getTarget(isDesktop)}
            onClick={this.clearProductData}
          >
            OTHER ELIGIBLE PRODUCTS
          </Link>
        </div>
      );
    }
    return null;
  }

  get promoBlock() {
    const { ProductLoader, promotions } = this.props;
    if (ProductLoader) {
      return (
        <div>
          <div className="nw-promo-title nwc-contentloader" />
          <ul className="nw-promo-list nwc-contentloader" />
        </div>
      );
    } else if (promotions && promotions.length > 0) {
      const promoArray = promotions.map(promo => {
        const displayPromo = promo.displayDiscount ? "nwc-hide" : "";
        if (!promo.displayDiscount) this.displayPromoBlock = true;
        return (
          <li className={`nw-promo-item ${displayPromo}`} key={promo.id}>
            <span className="nw-promo-name">{promo.displayName}</span>
            <div className="nw-promo-offer" id="promo">
              <Button
                id="promo-id"
                className="nw-promo-id"
                onClick={() => PromotionBlock.promoClick(promo.name)}
              >
                {promo.name}
              </Button>
              <div className="nw-promo-hover">Click To Copy Code</div>
            </div>
            <div className={`nw-promo-terms ${promo.tnc ? "" : "nwc-hide"}`}>
              <span className="nw-promo-tc">T&amp;C</span>
              <div className="nw-promo-hover">{promo.tnc}</div>
            </div>
            {this.handleShowEligibleProducts(promo)}
          </li>
        );
      });
      return (
        <div className={`nw-promo-block ${this.shouldPromoBlockDisplayed}`}>
          {/* <Button className="nw-promo-title" onClick={this.togglePromotions}>
            <i className="icomoon-pricetag" />
            &nbsp; Other Applicable Offers&nbsp;
            {this.state.isPromotions ? (
              <i className="icomoon-arrow_top" />
            ) : (
              <i className="icomoon-arrow_bottom" />
            )}
          </Button> */}
          <Label className="nw-pdp-promolabel" htmlFor="promolabel">
            OFFER:
          </Label>
          <ul className={`nw-promo-list ${this.isPromoHidden}`}>
            {promoArray}
          </ul>
        </div>
      );
    }
    return null;
  }
  togglePromotions() {
    const { isPromotions } = this.state;
    this.setState({ isPromotions: !isPromotions });
  }
  // promoClick(promo) {
  //   console.log(this);
  //   try {
  //     const inp = document.createElement("input");
  //     inp.value = promo;
  //     inp.select();
  //     document.execCommand("copy", false);
  //     inp.remove();
  //     Toastr.showToastr({
  //       className: "nwc-toastr-list-notification nw-toastr",
  //       message: "Text Copied",
  //       timeout: 3000,
  //     });
  //     Analytics.trackEvent({
  //       action: Event.action.OFFER_CODE_CLICK,
  //       label: promo,
  //       category: Event.category.PRODUCT_DESCRIPTION_PAGE,
  //     });
  //   } catch (err) {
  //     console.log("Oops, unable to copy");
  //   }
  // }
  clearProductData() {
    const { isDesktop } = this.props.CommonStore;
    if(!isDesktop) {
      this.props.ProductStore.clearProductData();
    }
    window.scrollTo(0, 0);
    Analytics.trackEvent({
      action: Event.action.OTHER_ELIGIBLE_PRODUCTS_CLICK,
      category: Event.category.PRODUCT_DESCRIPTION_PAGE,
    });
  }
  render() {
  return (
    <div className="nw-pdp-promoblockv1">
      {this.promoBlock}
    </div>
  );
  }
}

PromotionBlock.wrappedComponent.defaultProps = {
  promotions: [],
};

PromotionBlock.wrappedComponent.propTypes = {
  promotions: PropTypes.array, // eslint-disable-line
  ProductLoader: PropTypes.bool.isRequired,
  ProductStore: PropTypes.shape({
    clearProductData: PropTypes.func.isRequired,
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired,
  }).isRequired,
};

const getFinalComponent = component =>
  inject("ProductStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: PromotionBlock.wrappedComponent,
  component: PromotionBlock,
};

export default ProjectComponent;