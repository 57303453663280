const calculateColors = (pdpColors, colorDetails) => {
  const pcmColorArray = [colorDetails];
  const pcmColors = {
    "": pcmColorArray,
  };
  if (pdpColors && pdpColors.colors && pdpColors.total > 0) {
    return pdpColors.colors;
  }
  return pcmColors;
};

const setWishlist = styleId => {
  // TODO enable array wishlist post ODIN2.0 pdp

  // const wishlist = window.sessionStorage.getItem("wishList");
  // let wishListArray = [];
  // if (!wishlist) {
  //   wishListArray = [styleId];
  // } else {
  //   wishListArray = JSON.parse(wishlist);
  //   wishListArray.push(styleId);
  // }
  // const stringifiedData = JSON.stringify(wishListArray);
  // window.sessionStorage.setItem("wishList", stringifiedData);
  window.sessionStorage.setItem("wishList", styleId);
};

export { calculateColors, setWishlist };
