import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class ColorsAvail extends PureComponent {
  get moreColorsClassName() {
    return (!this.props.moreColors && "nwc-hide") || "";
  }

  get moreColorsText() {
    const { moreColors } = this.props;
    if (moreColors) {
      return `${moreColors} more ${(moreColors > 1 && "colors") || "color"}`;
    }

    return null;
  }

  render() {
    return (
      <div className={`nw-colorsavail ${this.moreColorsClassName}`}>
        <svg
          className="nw-colorsavail-svg"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="12"
          height="12"
          viewBox="0 0 32 32"
        >
          <path
            fill="#e0582a"
            d="M15.996 16.002l-9.381-12.912c2.853-2.072 5.855-3.048 9.381-3.048v15.96z"
          />
          <path
            fill="#ea862d"
            d="M15.996 16.002l-15.179-4.932c1.090-3.353 2.945-5.908 5.798-7.98l9.381 12.912z"
          />
          <path
            fill="#f7c723"
            d="M15.996 16.002l-15.179 4.932c-1.090-3.353-1.090-6.511 0-9.864l15.179 4.932z"
          />
          <path
            fill="#692d7b"
            d="M16 16.002l15.179 4.932c1.090-3.353 1.090-6.511 0-9.864l-15.179 4.932z"
          />
          <path
            fill="#529a43"
            d="M15.996 16.002l-9.381 12.912c-2.852-2.072-4.708-4.627-5.798-7.98l15.179-4.932z"
          />
          <path
            fill="#228672"
            d="M15.996 16.002v15.96c-3.526 0-6.529-0.976-9.381-3.048l9.381-12.912z"
          />
          <path
            fill="#415c9a"
            d="M15.996 16.002l9.381 12.912c-2.852 2.073-5.855 3.048-9.381 3.048v-15.96z"
          />
          <path
            fill="#5e5493"
            d="M15.996 16.002l15.179 4.932c-1.090 3.353-2.945 5.908-5.798 7.98l-9.381-12.912z"
          />
          <path
            fill="#913e86"
            d="M15.996 16.002l9.381-12.912c2.853 2.072 4.708 4.627 5.798 7.98l-15.179 4.932z"
          />
          <path
            fill="#d9242a"
            d="M15.996 16.002v-15.96c3.526 0 6.529 0.976 9.381 3.048l-9.381 12.912z"
          />
          <path
            fill="#fff"
            stroke="#fff"
            strokeWidth="2.2857"
            strokeMiterlimit="4"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            d="M12.148 19.851c-1.028-1.028-1.594-2.395-1.594-3.848s0.566-2.82 1.594-3.848c1.028-1.028 2.395-1.594 3.848-1.594s2.82 0.566 3.848 1.594c1.028 1.028 1.594 2.395 1.594 3.848s-0.566 2.82-1.594 3.848c-1.028 1.028-2.395 1.594-3.848 1.594s-2.82-0.566-3.848-1.594z"
          />
        </svg>
        <span className="nw-colorsavail-text">{this.moreColorsText}</span>
      </div>
    );
  }
}

ColorsAvail.defaultProps = {
  moreColors: null,
};

ColorsAvail.propTypes = {
  moreColors: PropTypes.number,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: ColorsAvail,
  component: ColorsAvail,
};

export default ProjectComponent;
