import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { Label, Button } from "arv-reactcomponents";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import { Toastr } from "arv-reactcomponents";
import { subPromoSeparator, PDP_CONFIG } from "npmlinks-constants";

@inject("ProductStore", "CommonStore")
@observer
class PromotionBlockV2 extends Component {
  static redirectToListing(promo) {
    const brand = promo.brands.length && promo.brands[0] ? `${subPromoSeparator}${promo.brands[0]}`: "";
    return `/products?f-pro=${encodeURI(
      promo.displayName
    )}${brand}`;
  }
  static getTarget(isDesktop) {
    return isDesktop ? "_blank" : "";
  }
  static promoClick(promo) {
    try {
      const el = document.createElement("textarea");
      el.value = promo;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy", false);
      document.body.removeChild(el);
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: "Text Copied",
        timeout: 3000
      });
      Analytics.trackEvent({
        action: Event.action.OFFER_CODE_CLICK,
        label: promo,
        category: Event.category.PRODUCT_DESCRIPTION_PAGE
      });
    } catch (err) {
      console.log("Oops, unable to copy");
    }
  }
  constructor() {
    super();
    this.state = {
      isPromotions: false
    };
    this.displayPromoBlock = false;
    this.togglePromotions = this.togglePromotions.bind(this);
    this.clearProductData = this.clearProductData.bind(this);
    this.handleShowEligibleProducts = this.handleShowEligibleProducts.bind(
      this
    );
  }
  get isPromoHidden() {
    const { isPromotions } = this.state;
    return !isPromotions ? "" : "nwc-hide";
  }
  get shouldPromoBlockDisplayed() {
    return this.displayPromoBlock ? "" : "nwc-hide";
  }

  handleShowEligibleProducts(promo) {
    const { isDesktop } = this.props.CommonStore;
    if (
      promo &&
      (Object.keys(promo).indexOf("showEligibleProducts") === -1 ||
        promo.showEligibleProducts)
    ) {
      return (
        <div className="nw-promo-othereligible-cta">
          <Link
            className="nwc-btn nwc-btn-secondary nw-other-eligible-cta"
            href={PromotionBlockV2.redirectToListing(promo)}
            to={PromotionBlockV2.redirectToListing(promo)}
            target={PromotionBlockV2.getTarget(isDesktop)}
            onClick={this.clearProductData}
          >
            {PDP_CONFIG.VIEW_OTHER_ELIGIBLE_PRODUCTS}
          </Link>
        </div>
      );
    }
    return null;
  }

  get promoBlock() {
    const { ProductLoader, promotions } = this.props;
    if (ProductLoader) {
      return (
        <div>
          <div className="nw-promo-title nwc-contentloader" />
          <ul className="nw-promo-list nwc-contentloader" />
        </div>
      );
    } else if (promotions && promotions.length > 0) {
      const promoArray = promotions.map(promo => {
        const displayPromo = promo.displayDiscount ? "nwc-hide" : "";
        if (!promo.displayDiscount) this.displayPromoBlock = true;
        return (
          <div className={`nw-pdp-offer-item nw-pdp-offer-itemv2 ${displayPromo}`} key={promo.id}>
            <div className="nw-offer-content">
              <div>
              Use
              <span className="nw-offer-code" id="promo">
                <Button
                  id="promoV2-id"
                  className="nw-promo-codebtn"
                  onClick={() => PromotionBlockV2.promoClick(promo.name)}
                >
                  {promo.name}
                </Button>
                <div className="nw-promo-hover">Click To Copy Code</div>
              </span>
              </div>
            </div>
            <div className="nw-offer-validon">
              <div className="nw-offer-amount">{promo.displayName} </div>
              <div className={`nw-promo-terms ${promo.tnc ? "" : "nwc-hide"}`}>
                <span className="nw-promo-tc">&nbsp;T&amp;C's Apply</span>
                <div className="nw-promo-hover">{promo.tnc}</div>
              </div>
            </div>
            <div className="nw-offer-code">
              {this.handleShowEligibleProducts(promo)}
            </div>
          </div>
        );
      });
      return (
        <div className={`nw-pdp-promoblockv2 ${this.shouldPromoBlockDisplayed}`}>
          <Label className="nw-pdp-promolabel" htmlFor="promolabel">
            {PDP_CONFIG.OFFERS_TEXT} :
          </Label>
          <div className="nw-pdp-promosubcontainer">{promoArray}</div>
        </div>
      );
    }
    return null;
  }
  togglePromotions() {
    const { isPromotions } = this.state;
    this.setState({ isPromotions: !isPromotions });
  }

  clearProductData() {
    const { isDesktop } = this.props.CommonStore;
    if (!isDesktop) {
      this.props.ProductStore.clearProductData();
    }
    window.scrollTo(0, 0);
    Analytics.trackEvent({
      action: Event.action.OTHER_ELIGIBLE_PRODUCTS_CLICK,
      category: Event.category.PRODUCT_DESCRIPTION_PAGE
    });
  }
  render() {
  return <div>{this.promoBlock}</div>;
  }
}

PromotionBlockV2.wrappedComponent.defaultProps = {
  promotions: []
};

PromotionBlockV2.wrappedComponent.propTypes = {
  promotions: PropTypes.array, // eslint-disable-line
  ProductLoader: PropTypes.bool.isRequired,
  ProductStore: PropTypes.shape({
    clearProductData: PropTypes.func.isRequired
  }).isRequired,
  CommonStore: PropTypes.shape({
    isDesktop: PropTypes.bool.isRequired
  }).isRequired
};

const getFinalComponent = component =>
  inject("ProductStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: PromotionBlockV2.wrappedComponent,
  component: PromotionBlockV2
};

export default ProjectComponent;
