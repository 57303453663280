import React, { Component } from "react";
import PropTypes from "prop-types";
import loadashGet from "lodash.get";
import {
  Button,
  Input,
  Toastr,
  Loader,
  GridContainer,
  GridColumn,
  GridRow,
} from "arv-reactcomponents";
import {
  setLastUsedPincode,
  getlastUsedPincode,
} from "../../../utils";
import { CONTACT_US_V2, PDP_CONFIG } from "npmlinks-constants";
import Analytics from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import { observer, inject } from "mobx-react";

@inject("ProductStore")
@observer
class PincodeBlockV2 extends Component {
  constructor() {
    super();
    this.state = {
      isPincodeValid: false,
      pincode: "",
      isModalOpen: false,
      pincodeErrorMsg: "",
      loader: false,
    };
    this.selectStore = null;
    this.pincodeInput = null;
    this.pinCodeSubmit = this.pinCodeSubmit.bind(this);
    this.validatePincode = this.validatePincode.bind(this);
    this.openStoresModal = this.openStoresModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getPinCode = this.getPinCode.bind(this);
    this.resetData = this.resetData.bind(this);
  }
  componentDidMount() {
    this.getPinCode();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedSkuId !== this.props.selectedSkuId ||
      nextProps.styleId !== this.props.styleId
    ) {
      this.resetData();
      this.getPinCode();
    }
  }
  get isPincodeValid() {
    return !this.state.isPincodeValid;
  }
  get isPinCodeError() {
    return this.state.isPincodeValid ? "nwc-hide" : "";
  }
  get isLoader() {
    return this.state.loader ? "" : "nwc-hide";
  }

  getPinCode() {
    const pincode = getlastUsedPincode();
    const validatePincodeField = this.pincodeInput.getValidatedOutput(pincode);
    if (validatePincodeField.value.length < 6)
      validatePincodeField.isValid = false;
    if (validatePincodeField.isValid)
      this.setState({ pincode, isPincodeValid: validatePincodeField.isValid });
  }
  resetData() {
    this.setState({ pincodeErrorMsg: "" });
    this.props.ProductStore.update_deliveryModes(false, false, false, [], null);
  }
  openStoresModal() {
    this.setState({
      isModalOpen: true,
    });

    Analytics.trackEvent({
      action: Event.action.STORES_NEARBY_CLICK,
      category: Event.category.PRODUCT_DESCRIPTION_PAGE,
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
    });
  }

  pinCodeSubmit(e, initPincode, interaction = false) {
    if (e) {
      e.preventDefault();
    }
    const pincode = initPincode || this.state.pincode;
    const { selectedSkuId: skuId } = this.props;
  
    Analytics.trackEvent({
      action: Event.action.PINCODE_ENTERED,
      label: pincode,
      category: Event.category.PRODUCT_DESCRIPTION_PAGE,
    });
    if (!skuId) {
      Toastr.showToastr({
        className: "nwc-toastr-list-notification nw-toastr",
        message: "Hold on! What size do you want?",
        timeout: 3000,
      });

      Analytics.trackEvent({
        action: Event.action.PINCODE_CHECKED_SIZE_ISSUE,
        category: Event.category.PRODUCT_DESCRIPTION_PAGE,
      });

      return null;
    }
    const skus = {
      skuId,
      qty: 1,
      quantity: 1,
    };
    const body = {
      skus: [skus],
      pincode,
    };
    this.setState({ loader: true, isPincodeValid: false });
    setLastUsedPincode(pincode);
    this.props
      .getShippingDetails(JSON.stringify(body))
      .then(res => {
        const {
          availableShippingMethods = [],
          fulfillmentCenters = [],
          storePickUpIncentive = {},
          deliveryModes,
        } = res;
        if (availableShippingMethods && availableShippingMethods.length > 0) {

          const sdd = deliveryModes && deliveryModes.find(item => item.deliveryMode === "sdd" && item.deliveryMode);
          const ndd = deliveryModes && deliveryModes.find(item => item.deliveryMode === "ndd" && item.deliveryMode);
          const standard = deliveryModes && deliveryModes.find(item => item.deliveryMode === "standard" && item.deliveryMode);
          
  
          const incentiveValue = loadashGet(
            storePickUpIncentive,
            "data.discount",
            0,
          );
          this.setState({
            loader: false,
            isPincodeValid: true,
          });
          
          Analytics.trackEvent({
            action: Event.action.PINCODE_CHECKED,
            label: pincode,
            category: Event.category.PRODUCT_DESCRIPTION_PAGE,
            interaction,
          });
          this.props.ProductStore.update_deliveryModes(sdd, ndd, standard, fulfillmentCenters, incentiveValue);
        } else {
          this.setState({
            pincodeErrorMsg:
              "Sorry we don't deliver to this pincode yet. Please try another one.",
            loader: false,
            isPincodeValid: false,
          });
          this.props.ProductStore.update_deliveryModes(false, false, false, [], null);
          Analytics.trackEvent({
            action: Event.action.PINCODE_ERROR,
            label: pincode,
            category: Event.category.PRODUCT_DESCRIPTION_PAGE,
            interaction,
          });
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => {
        this.setState({
          loader: false,
          isPincodeValid: true,
        });
        this.props.ProductStore.update_deliveryModes(false, false, false, [], null);
        Toastr.showToastr({
          className: "nwc-toastr-list-danger nw-toastr",
          message: CONTACT_US_V2.errorMsg,
          timeout: 3000,
        });
      });
    return null;
  }
  validatePincode(e) {
    const validatePincodeField = this.pincodeInput.getValidatedOutput(
      e.target.value,
    );
    if (validatePincodeField.value.length < 6)
      validatePincodeField.isValid = false;
    this.setState({
      isPincodeValid: validatePincodeField.isValid,
      pincode: validatePincodeField.value,
      modes: [],
      pincodeErrorMsg: validatePincodeField.errorMsg,
    });
    const { selectedSkuId: skuId } = this.props;
    if (validatePincodeField.isValid && skuId) {
      this.pinCodeSubmit(null, validatePincodeField.value);
    }
  }
  render() {
    const {
      pincode,
      pincodeErrorMsg,
    } = this.state;
    const { className, inStock } = this.props;

    return (
      <div className={`nw-pdp-pincodev2 ${className}`}>
        <div className="nw-pdp-promolabel">{PDP_CONFIG.CHECK_DELIVERY_OPTION_TEXT}</div>
        <form className="nw-pincode-btns" onSubmit={this.pinCodeSubmit}>
          <GridContainer>
            <GridRow>
              <GridColumn className="nw-pincode-inputblock nwc-grid-col-xs-8">
                <Input
                  className="nw-pincode-inputv2"
                  name="pincode"
                  placeholder={PDP_CONFIG.PINCODE_INPUT_PLACE_HOLDER}
                  type="tel"
                  ref={values => {
                    this.pincodeInput = values;
                  }}
                  value={pincode}
                  maxLength={6}
                  minLength={6}
                  onChange={this.validatePincode}
                  disabled={!inStock}
                  validateWithPattern={[
                    {
                      pattern: /^[0-9]*$/,
                      msg:
                        "We need a valid 6-digit pincode. Try again, please.",
                    },
                  ]}
                />
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs-4">
                <div>
                  <Button
                    type="submit"
                    className="nw-pincode-btnv2"
                    disabled={this.isPincodeValid || !inStock}
                  >
                    {PDP_CONFIG.CHECK_BTN_TEXT}
                    <Loader className={`nw-pincode-loader ${this.isLoader}`} />
                  </Button>
                </div>
              </GridColumn>
            </GridRow>
          </GridContainer>
          <div className={`nw-pincode-error ${this.isPinCodeError}`}>
            {pincodeErrorMsg}
          </div>
        </form>
      </div>
    );
  }
}
PincodeBlockV2.defaultProps = {
  selectedSkuId: "",
  className: "",
  styleId: "",
  inStock: false,
  selectStore: null,
  pincode: "",
};
PincodeBlockV2.propTypes = {
  selectedSkuId: PropTypes.string,
  className: PropTypes.string,
  styleId: PropTypes.string,
  getShippingDetails: PropTypes.func.isRequired,
  inStock: PropTypes.bool,
  selectStore: PropTypes.func,
  pincode: PropTypes.string,
};

const getFinalComponent = component =>
  inject("ProductStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: PincodeBlockV2,
  component: PincodeBlockV2,
};

export default ProjectComponent;
