/* eslint-disable no-restricted-syntax */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { toJS } from "mobx";
import { observer, inject } from "mobx-react";

import SizeRecommendationBlock from "../SizeRecommendationBlock";
import { Button } from "arv-reactcomponents";
import Analytics from "../../../analytics";
import GTM from "../../../analytics/GTM";
import Event from "../../../analytics/eventFactory";
import { PAYMENTPAGE } from "npmlinks-constants";
import {
  PDP_SIZE_BLOCK_TITLE,
  PDP_SIZE_CHART_LABEL,
  PDP_SIZE_MODAL_SUBTEXT,
  pageType,
} from "npmlinks-constants";

@inject("ProductStore")
@observer
class SizeBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMeasurement: null,
    };

    this.selectSku = this.selectSku.bind(this);
    this.onClickfitGuideModal = this.onClickfitGuideModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sizeChartId !== this.props.sizeChartId) {
      this.setState({ selectedMeasurement: null });
    }
  }

  get properMeasurementsObject() {
    const { ProductStore } = this.props;
    const { sizeChartData } = ProductStore;
    const measurements =
      sizeChartData &&
      sizeChartData.data &&
      sizeChartData.data.data &&
      toJS(sizeChartData.data.data.measurements);
    const obj = {};

    for (const unit in measurements) {
      if (unit && measurements[unit]) {
        measurements[unit].forEach((value, key1) => {
          if (unit !== "inch") {
            return;
          }

          let measurementsObj = [];
          const unitData = value.filter(item => item.value !== "");
          if (unitData) {
            unitData.forEach((val, key) => {
              if (key === 0) {
                measurementsObj = obj[val.value] || measurementsObj;
                const valInLowerCase = (val.value || "")
                  .replace(/\s/g, "")
                  .toLowerCase();
                obj[valInLowerCase] = measurementsObj;
              } else {
                measurementsObj.push({
                  attribute: val.attribute,
                  value: val.value,
                  unit,
                });
              }
            });
          }
        });
      }
    }

    return obj;
  }

  onClickfitGuideModal() {
    this.props.fitGuideModal();
    const category =
      window.pageType === pageType.pdp
        ? Event.category.PRODUCT_DESCRIPTION_PAGE
        : Event.category.FAVOURITES_PAGE;
    Analytics.trackEvent({
      action: Event.action.FIT_GUIDE_CLICK,
      category,
    });
  }

  selectSku(sku) {
    const { selectSku } = this.props;
    const skuSizeInLowerCase = (sku.tenantSize || sku.size || "")
      .replace(/\s/g, "")
      .toLowerCase();

    this.setState({
      selectedMeasurement: this.properMeasurementsObject[skuSizeInLowerCase],
    });

    selectSku(sku);
    GTM.pushWithEvent({sizeSelected: skuSizeInLowerCase}, Event.GTMEvent.SELECT_SIZE);
  }

  addUnitIfNone(value, unit) {
    if (!/[a-zA-Z]/g.test(value)) {
      return `${value} ${unit}`;
    }

    return value;
  }

  get sizeBlockTitle() {
    const { tenantSizeLabel} = this.props;
    return tenantSizeLabel || PDP_SIZE_BLOCK_TITLE;
  }

  render() {
    let sizeArray = [];
    const {
      className,
      skus,
      selectedSkuId,
      ProductLoader,
      sizeChartId,
      sizeRecommendation,
    } = this.props;

    const { selectedMeasurement } = this.state;

    let sizeBlock = null;
    if (ProductLoader) {
      sizeBlock = (
        <div className="nw-sizeblock">
          <div className="nw-sizeblock-title nwc-contentloader" />
          <div className="nw-sizeblock-list nwc-contentloader" />
        </div>
      );
    } else if (skus && skus.length > 0) {
      sizeArray = skus.map(sku => {
        const disabled = !sku.inStock || !sku.sellableQuantity > 0;
        const sizeAttr = {
          disabled,
        };
        const selected = selectedSkuId === sku.skuId ? "selected" : "";
        const skuFinalSize = sku.tenantSize || sku.size || "";
        const skuSizeInLowerCase = skuFinalSize
          .replace(/\s/g, "")
          .toLowerCase();
        const eachMeasurementObj = this.properMeasurementsObject[
          skuSizeInLowerCase
        ];

        return (
          <Button
            itemProp="offers"
            itemScope
            itemType="http://schema.org/Offer"
            className={`nw-size-chip  ${selected}`}
            key={sku.skuId}
            onClick={() => this.selectSku(sku)}
            {...sizeAttr}
          >
            <span itemProp="sku" className="nwc-hide">
              {sku.skuId}
            </span>
            <span itemProp="priceCurrency" content="INR" className="nwc-hide">
            {`${PAYMENTPAGE.NNNOW_CURRENCY}`}
            </span>
            <span itemProp="price" className="nwc-hide">
              {sku.price}
            </span>
            <span
              itemProp="availability"
              href="http://schema.org/InStock"
              className="nwc-hide"
            >
              {sku.inStock ? "InStock" : "OutOfStock"}
            </span>
            {sku.tenantSize || sku.size}
            {(eachMeasurementObj && (
              <div className="nw-sizeblock-buttonhover nw-desktop-only">
                <div className="nw-sizeblock-buttonhoverwrapper">
                  {eachMeasurementObj.map(obj => (
                    <div key={obj.attribute}>
                      <span className="nw-sizeblock-hoverlabel">{`${
                        obj.attribute
                      }:`}</span>
                      {` ${obj.value}`}
                    </div>
                  )) || null}
                  <div className="nw-sizeblock-unitmsg">*Units in inches</div>
                </div>
              </div>
            )) ||
              null}
          </Button>
        );
      });
      sizeBlock = (
        <div className={`nw-sizeblock-container ${className} `}>
          <div className="nw-sizeblock-label">
            <div className="nw-sizeblock-title">{this.sizeBlockTitle}:</div>
            {sizeChartId && (
              <Button
                className="nw-sizechart-button"
                onClick={this.onClickfitGuideModal}
              >
                {PDP_SIZE_CHART_LABEL}
              </Button>
            )}
          </div>
          <div className="nw-sizeblock-list nwc-custom-scrollbar">
            {sizeArray}
            {(selectedMeasurement && (
              <div className="nw-sizeblock-mbuttonhover nw-mobile-only">
                <div className="nw-sizeblock-mbuttonhoverwrapper">
                  {selectedMeasurement.map(obj => (
                    <div
                      className="nw-sizeblock-meachmeasurement"
                      key={obj.attribute}
                    >
                      <span className="nw-sizeblock-mhoverlabel">{`${
                        obj.attribute
                      }:`}</span>
                      {` ${obj.value}`}
                    </div>
                  )) || null}
                </div>
                <div className="nw-sizeblock-unitmsg">*Units in inches</div>
              </div>
            )) ||
              null}
          </div>
          <SizeRecommendationBlock.component
            sizeRecommendation={sizeRecommendation}
          />
          <div className="nw-pdp-sizeprompttext2">
            {sizeChartId && PDP_SIZE_MODAL_SUBTEXT}
            {sizeChartId && (
              <Button
                className="nw-sizechart-modalbutton"
                onClick={this.onClickfitGuideModal}
              >
                {PDP_SIZE_CHART_LABEL}
              </Button>
            )}
          </div>
        </div>
      );
    }
    return sizeBlock;
  }
}

SizeBlock.defaultProps = {
  className: "",
  selectedSkuId: "",
  skus: [],
  sizeChartId: "",
  fitGuideModal: "",
  sizeRecommendation: "",
  sizeMappingEnabled: false,
};

SizeBlock.propTypes = {
  className: PropTypes.string,
  sizeRecommendation: PropTypes.string,
  selectSku: PropTypes.func.isRequired,
  skus: PropTypes.arrayOf(PropTypes.shape({})),
  selectedSkuId: PropTypes.string,
  sizeChartId: PropTypes.string,
  ProductLoader: PropTypes.bool.isRequired,
  fitGuideModal: PropTypes.func,
  ProductStore: PropTypes.shape({
    sizeChartData: PropTypes.shape({}),
  }).isRequired,
  tenantSizeLabel: PropTypes.string,
  sizeMappingEnabled: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: SizeBlock,
  component: SizeBlock,
};

export default ProjectComponent;
