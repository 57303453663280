import React from "react";
import PropTypes from "prop-types";

const SizeRecommendationBlock = props => {
  const { sizeRecommendation } = props;
  return sizeRecommendation ? (
    <div className="nw-size-recommendation">
      <i className="nw-size-recommendationicon icomoon-notification"/>
      <div className="nw-size-recommendationhead">IMPORTANT</div>
      <span>{sizeRecommendation}</span>
    </div>
   ) : null;
};

SizeRecommendationBlock.defaultProps = {
  sizeRecommendation: "",
};

SizeRecommendationBlock.propTypes = {
  sizeRecommendation: PropTypes.string,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: SizeRecommendationBlock,
  component: SizeRecommendationBlock,
};

export default ProjectComponent;
