import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";

import { PDP_PRODUCTVIEWBUTTON_TEXTS, LISTING } from "npmlinks-constants";
import { GridRow, GridColumn, Utils, Modal } from "arv-reactcomponents";
import { imageSize, isIframe } from "../../../utils";
import Analytics, { GTM } from "../../../analytics";
import Event from "../../../analytics/eventFactory";
import { setWishlist } from "../ProductUtil";
import ProductModal from "../ProductModal";

@inject("LoginStore", "WishListStore", "CommonStore")
@observer
class ProductBtns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };

    this.onShopNnnowClick = this.onShopNnnowClick.bind(this);
    this.onShortListClick = this.onShortListClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.triggerGTM = this.triggerGTM.bind(this);
  }

  triggerGTM(eventName) {
    const {
      promotions,
      mainImage,
      brandText,
      productTitle,
      styleId,
      mrpRange,
      sellingPriceRange,
      discountRange,
    } = this.props;
    GTM.pushWithEvent({ 
        promotions,
        mainImage,
        brandText,
        productTitle,
        styleId,
        mrpRange,
        sellingPriceRange,
        discountRange,
      },
      eventName,
    );
  }

  onShopNnnowClick(e) {
    const { isDesktop } = this.props.CommonStore;

    Utils.preventDefault(e);
    Utils.preventEventPropagation(e);
    this.setState({
      isModalOpen: true,
    });

    const { index, productUrl, origin } = this.props;
    const page = Math.ceil((index || 1) / imageSize);
    const device = (isDesktop && "desktop") || "mobile";
    if (origin) {
      Analytics.trackEvent({
        action: Event.action.getShopNNNOWFromPdp(
          page,
          imageSize,
          device,
          index,
          origin,
        ),
        label: Event.label.getSource(productUrl),
        category: Event.category.PRODUCT_DESCRIPTION_PAGE,
      });
    } else {
      Analytics.trackEvent({
        action: Event.action.getShopNNNOW(page, imageSize, device, index),
        label: Event.label.getSource(productUrl),
        category: Event.category.LISTING_PAGE,
      });
    }
    this.triggerGTM(Event.GTMEvent.SHOP_NOW_CLICK);

  }

  onShortListClick(e) {
    const { isDesktop } = this.props.CommonStore;

    Utils.preventDefault(e);
    Utils.preventEventPropagation(e);
    const { index, styleId, LoginStore, origin, productUrl } = this.props;
    const page = Math.ceil((index || 1) / imageSize);
    const device = (isDesktop && "desktop") || "mobile";
    const { isUserLoggedIn } = LoginStore;
    if (origin) {
      Analytics.trackEvent({
        action: Event.action.getFavClickFromPdp(
          page,
          imageSize,
          device,
          index,
          origin,
        ),
        label: Event.label.getSource(productUrl),
        category: Event.category.PRODUCT_DESCRIPTION_PAGE,
      });
    } else {
      Analytics.trackEvent({
        action: Event.action.getFavClick(page, imageSize, device, index),
        label: Event.label.getSource(productUrl),
        category: Event.category.LISTING_PAGE,
      });
    }

    if (isUserLoggedIn) {
      this.props.WishListStore.wishList(styleId);
    } else {
      setWishlist(this.props.styleId);
      this.props.LoginStore.setloginModal(true);
    }
    this.triggerGTM(Event.GTMEvent.PRODUCT_WISHLIST);
  }

  get containerClassName() {
    if (this.props.promotions.length && !this.props.productTags.length) {
      return "nw-productbtns-salecontainer";
    }
    return "nw-productbtns-container";
  }
  get saleTextClassName() {
    return (this.props.promotions.length === 0 && "nwc-hide") || "";
  }

  get isAllPromotionsClassName() {
    return (this.props.isAllPromotions && "is-all-promotiions") || "";
  }

  openModal(e) {
    const { isDesktop } = this.props.CommonStore;

    e.stopPropagation();
    this.setState({
      isModalOpen: true,
    });

    const { index, styleId, origin } = this.props;
    const page = Math.ceil((index || 1) / imageSize);
    const device = (isDesktop && "desktop") || "mobile";
    if (origin) {
      Analytics.trackEvent({
        action: Event.action.getShopNNNOWFromPdp(
          page,
          imageSize,
          device,
          index,
          origin,
        ),
        label: styleId,
        category: Event.category.PRODUCT_DESCRIPTION_PAGE,
      });
    } else {
      Analytics.trackEvent({
        action: Event.action.getShopNNNOW(page, imageSize, device, index),
        label: styleId,
        category: Event.category.LISTING_PAGE,
      });
    }
  }

  closeModal(e) {
    if (e) e.stopPropagation();
    this.setState({
      isModalOpen: false,
    });
    Analytics.trackEvent({
      action: Event.action.MINI_PDP_CLOSE,
      category: Event.category.PRODUCT_DESCRIPTION_PAGE,
    });
  }

  get returnOfferBlock() {
    if (!this.props.productTags.length) {
      return (
        <span className={`nw-productbtns-saletxt ${this.saleTextClassName}`}>
          {LISTING.OFFER_TEXT}
        </span>
      );
    }
    return null;
  }

  render() {
    const {
      mainImage,
      brandText,
      productTitle,
      discountRange,
      styleId,
      isNew,
      mrpRange,
      sellingPriceRange,
    } = this.props;

    const { isModalOpen } = this.state;
    if (isIframe) {
      return null;
    }
    return (
      <Fragment>
        <GridRow
          className={`${this.containerClassName} ${
            this.isAllPromotionsClassName
          }`}
        >
          <GridColumn className="nw-productbtns">
            {this.returnOfferBlock}
            <GridRow className="nw-productbtns-row nw-desktop-only">
              <GridColumn className="nwc-grid-col-xs-6 nw-productbtns-btncol">
                <div
                  role="button"
                  className="nwc-btn nw-productbtns-btn"
                  onClick={this.onShopNnnowClick}
                  onKeyDown={Utils.noop}
                  tabIndex={0}
                >
                  <i className="icomoon-bag1" />
                  <span className="nw-productbtns-btntxt">
                    {PDP_PRODUCTVIEWBUTTON_TEXTS.shop}
                  </span>
                </div>
              </GridColumn>
              <GridColumn className="nwc-grid-col-xs-6 nw-productbtns-btncol  nw-productbtns-btncol2">
                <div
                  role="button"
                  className="nwc-btn nw-productbtns-btn"
                  onClick={this.onShortListClick}
                  onKeyDown={Utils.noop}
                  tabIndex={0}
                >
                  <i className="nw-productbtns-headericon icomoon-heart-o" />
                  <span className="nw-productbtns-btntxt">
                    {PDP_PRODUCTVIEWBUTTON_TEXTS.favourite}
                  </span>
                </div>
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
        <Modal
          className="nw-quick-view"
          isOpen={isModalOpen}
          onClose={this.closeModal}
        >
          <ProductModal.component
            mainImage={mainImage}
            brandText={brandText}
            productTitle={productTitle}
            discountRange={discountRange}
            styleId={styleId}
            isNew={isNew}
            mrpRange={mrpRange}
            sellingPriceRange={sellingPriceRange}
            closeModal={this.closeModal}
          />
        </Modal>
      </Fragment>
    );
  }
}

ProductBtns.wrappedComponent.defaultProps = {
  mainImage: null,
  isNew: false,
  origin: null,
  discountRange: {
    min: 0,
    max: 0,
  },
  mrpRange: {
    min: 0,
    max: 0,
  },
  sellingPriceRange: {
    min: 0,
    max: 0,
  },
  productTags: [],
};

ProductBtns.wrappedComponent.propTypes = {
  LoginStore: PropTypes.shape({
    setloginModal: PropTypes.func.isRequired,
    isUserLoggedIn: PropTypes.bool.isRequired,
  }).isRequired,
  WishListStore: PropTypes.shape({
    wishList: PropTypes.func.isRequired,
  }).isRequired,
  promotions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isAllPromotions: PropTypes.bool.isRequired,
  origin: PropTypes.string,
  index: PropTypes.number.isRequired,
  styleId: PropTypes.string.isRequired,
  mainImage: PropTypes.string,
  brandText: PropTypes.string.isRequired,
  productTitle: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  discountRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  mrpRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  sellingPriceRange: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  productTags: PropTypes.arrayOf(
    PropTypes.shape({
      tagUrl: PropTypes.string,
      tagText: PropTypes.string,
      tagTextColor: PropTypes.string,
    }),
  ),
  productUrl: PropTypes.string.isRequired
};

const getFinalComponent = component =>
  inject("LoginStore", "WishListStore", "CommonStore")(observer(component));

const ProjectComponent = {
  getFinalComponent,
  wrapper: ProductBtns.wrappedComponent,
  component: ProductBtns,
};

export default ProjectComponent;
