import Event from "./eventFactory";
import { postFunction } from "./logman";
import { getClientUniqueId } from "../utils";


const staticHeaders = {
    "X-Channel": "odin",
    "accept": "application/json",
    "Content-Type": "application/json",
    "bbversion": "v2",
    "clientSessionId": getClientUniqueId() || "",
    "X-Domain": process.env.REACT_APP_DOMAIN_NAME,
};

 
const pushToData =  function(props) {
    const body={
        category: Event.category.LISTING_PAGE,
        action: Event.action.PRODUCT_CAROUSEL,
        data: [props]
    };
    pushToDataService(body);
};

const pushToDataService = (body) => postFunction("eventPublish", staticHeaders , JSON.stringify(body));

export default pushToData;