import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class ProductPromotions extends PureComponent {
  get morePromotionsText() {
    const displayedDiscount = [];
    const notDisplayedDiscount = [];
    this.props.promotions.filter(item => {
      if (item.displayDiscount) {
        displayedDiscount.push(item);
      } else {
        notDisplayedDiscount.push(item);
      }
      return null;
    });

    if (notDisplayedDiscount.length === 1 && displayedDiscount.length) {
      return "1 more offer";
    } else if (notDisplayedDiscount.length === 1 && !displayedDiscount.length) {
      return notDisplayedDiscount[0].displayName;
    } else if (notDisplayedDiscount.length > 1) {
      return `${notDisplayedDiscount.length} more offers`;
    }

    return null;
  }

  render() {
    return (
      <div className="nw-productpromotions">{this.morePromotionsText}</div>
    );
  }
}

ProductPromotions.propTypes = {
  promotions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const getFinalComponent = component => component;

const ProjectComponent = {
  getFinalComponent,
  wrapper: ProductPromotions,
  component: ProductPromotions,
};

export default ProjectComponent;
